<template>
  <div class="activity-list d-flex flex-column">
    <v-row v-if="rawActivities.length" class="tabs-toolbar align-self-end justify-end align-center mx-4">
      <v-btn class="mr-6" depressed :color="showSelect ? '' : 'primary'" @click="showSelect = !showSelect" v-text="$t('activity.publishActivities')" />
      <v-autocomplete
        v-if="projectData.tags && projectData.tags.length"
        v-model="tag"
        :items="projectData.tags"
        :label="$t('common.tag')"
        class="mr-6"
        outlined
        dense
        hide-details
        clearable
      />
      <v-text-field
        v-model="search"
        class="flex-grow-0"
        :label="$t('common.searchByName')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <!-- Tabs -->
    <v-tabs v-model="tab" color="secondary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" v-text="name" />
    </v-tabs>
    <v-divider class="mb-4" />

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, content } in tabs" :key="id">
        <!-- Table -->
        <v-data-table
          v-if="content.length"
          v-model="itemsSelected"
          :headers="headers"
          :items="content"
          :search="search"
          :show-select="showSelect"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          dense
          class="table--has-pointer" @click:row="({ id }) => $router.push({ name: 'activity', params: { activityId: id }})"
        >
          <template #item.date="{ item }">
            <span v-if="item.date" class="pa-0" v-text="item.date" />
          </template>
          <template #item.time="{ item }">
            <span v-if="item.time" class="pa-0" v-text="item.time" />
          </template>
          <template #item.status="{ item }" :text-color="getColor(item.status)">
            <span class="pa-0" :style="{ color: getColor(item.status)}" v-text="$t(`activity.status.${item.status}`)" />
          </template>
          <!-- Actions -->
          <template #item.actions="{ item }">
            <v-menu bottom left>
              <template #activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <!-- Menu list -->
              <v-list>
                <v-list-item @click="createClone({ organizationId, projectId, activityId: item.id })">
                  <v-list-item-title>{{ $t('common.duplicate') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status !== 'archived'" @click="archive({ organizationId, projectId, activity: item })">
                  <v-list-item-title>{{ $t('common.archive') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="unarchive({ organizationId, projectId, activity: item })">
                  <v-list-item-title>{{ $t('common.unarchive') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$confirm($t('common.confirm')).then( (res) => res ? remove({ activity: item }) : '' ) ">
                  <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <empty-list v-else :content="$t('common.activities')" />
      </v-tab-item>
    </v-tabs-items>

    <!-- Button create -->
    <v-btn v-if="!showSelect" bottom color="primary" fab fixed z-index="3000" right :to="{ name: 'project-create-activity', query: { date } }">
      <v-icon>add</v-icon>
    </v-btn>

    <div
      v-else
      class="d-flex justify-center align-center activity-list__actions-bottom"
    >
      <v-btn class="mr-2" depressed color="secundary" @click="showSelect = !showSelect" v-text="$t('common.cancel')" />
      <v-btn color="primary" depressed @click="publishSelected" v-text="$t('activity.publishActivities')" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'ActivityList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    date: { type: String, required: true },
  },
  data() {
    return {
      tab: null,
      DateTime,
      search: '',
      showSelect: false,
      itemsSelected: [],
      sortBy: 'name',
      sortDesc: false,
      tag: null,
      headers: [
        { text: 'Activity name', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Type', value: 'type' },
        { text: 'Date', value: 'date' },
        { text: 'Time', value: 'time' },
        { text: 'Actions', value: 'actions', filterable: false, sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters({ originalActivities: 'project/originalActivities' }),
    ...mapGetters({ rawActivities: 'project/activities' }),
    ...mapGetters({ projectData: 'project/data' }),
    activities({ rawActivities, projectData }) {
      let activities = rawActivities
      const { date, tag } = this
      if (date !== 'all') activities = this.filterActivitiesByDate({ activities, date })
      if (tag) activities = this.filterActivitiesByTag({ activities, tag })
      return activities.map(activity => ({
        ...activity,
        isSelectable: activity.status !== 'upToDate',
        ...(activity.date ? {
          date: DateTime.fromSeconds(activity.date.seconds, { zone: projectData.timezone }).toISODate(),
          time: DateTime.fromSeconds(activity.date.seconds, { zone: projectData.timezone }).toISOTime().slice(0, 5),
        } : { date: '-', time: '-' }),
      }))
    },
    tabs({ activities }) {
      return [
        {
          id: 'actives',
          name: this.$t('filters.active'),
          content: activities.filter(activity => activity.active),
        },
        {
          id: 'archived',
          name: this.$t('filters.archived'),
          content: activities.filter(activity => !activity.active),
        },
      ]
    },
  },
  methods: {
    ...mapActions('activity', ['archive', 'unarchive', 'createClone']),
    async publishSelected() {
      const { organizationId, projectId, originalActivities } = this
      if (!this.itemsSelected.length) return
      const selectedActivityIds = this.itemsSelected.map(item => item.id)
      const selectedActities = this.rawActivities.filter(activity => selectedActivityIds.includes(activity.id)).map(({ status, ...rest }) => rest)
      await this.runAsync(() => Promise.all(
        [...selectedActities.map(async selectedActivity => {
          const activity = originalActivities.find(e => selectedActivity.id === e.id)
          await this.$store.dispatch('activity/update', { organizationId, projectId, activity, data: selectedActivity, publish: true })
        }),
        ],
      ))
      this.showSelect = false
    },
    remove({ activity }) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('activity/delete', { organizationId, projectId, activity }))
    },
    filterActivitiesByDate({ activities, date }) {
      const timezone = this.projectData.timezone
      const filterMonth = +date.slice(5, 7)
      const filterDay = +date.slice(8, 10)
      return activities.filter(activity => {
        const actiDate = DateTime.fromSeconds(activity.date.seconds, { zone: timezone })
        return filterDay === actiDate.day && filterMonth === actiDate.month
      })
    },
    filterActivitiesByTag({ activities, tag }) {
      return activities.filter(activity => activity.tags?.includes(tag))
    },
    getColor(status) {
      return this.$vuetify.theme.themes.light[status] ?? 'red'
    },
  },
}
</script>

<style lang="scss">
  .activity-list {
    &__actions-bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 70px;
      background: whitesmoke;
    }
  }

  tr {
    cursor: pointer;
  }
</style>
